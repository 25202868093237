export const removeWrappingParagraphTag = markupStr => {
  if (
    markupStr.split('<p>').length - 1 === 1 &&
    markupStr.split('</p>').length - 1 === 1 &&
    markupStr.substring(0, 3) === '<p>' &&
    markupStr.substring(markupStr.length - 4, markupStr.length) === '</p>'
  ) {
    return markupStr.substring(3, markupStr.length - 4)
  } else {
    return markupStr
  }
}
