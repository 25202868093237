import React from 'react'
import styled from 'styled-components'
import {Fade} from 'react-awesome-reveal'

export default function HospitalLocation({
  className = '',
  heading,
  address,
  image1url,
  image1alt,
  image1caption,
  image2url,
  image2alt,
  image2caption,
  image3url,
  image3alt,
  image3caption,
  bodyText,
  phoneNumber,
  websiteUrl,
}) {
  const hasPhotos = image1url || image2url || image3url
  return (
    <StyledComponent className={`hospital-location ${className}`}>
      <Fade>
        <div>
          <h3 className="heading">{heading}</h3>
          <p className="address">{address}</p>
          {hasPhotos && (
            <div className="photos">
              {image1url && (
                <figure>
                  <img src={image1url} alt={image1alt} width="378" height="203" />
                  <figcaption>{image1caption}</figcaption>
                </figure>
              )}
              {image2url && (
                <figure>
                  <img src={image2url} alt={image2alt} width="378" height="203" />
                  <figcaption>{image2caption}</figcaption>
                </figure>
              )}
              {image3url && (
                <figure>
                  <img src={image3url} alt={image3alt} width="378" height="203" />
                  <figcaption>{image3caption}</figcaption>
                </figure>
              )}
            </div>
          )}
          <div className="body-text" dangerouslySetInnerHTML={{__html: bodyText}} />
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  .heading {
    font-weight: 500;
    margin: 0 0 1rem 0;
    text-transform: uppercase;
  }
  .address {
    margin: 0 0 1rem 0;

    @media (min-width: 700px) {
      margin: 0 0 2rem 0;
    }
  }
  .photos {
    display: flex;
    flex-direction: column;

    @media (min-width: 700px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    figure {
      border-left: 1px solid black;
      margin: 0;
      padding: 2rem 2rem;

      @media (min-width: 600px) {
        padding: 2rem 3rem;
      }

      @media (min-width: 700px) {
        padding: 0 3rem 0 3rem;
      }

      img {
        margin: 0 0 1rem 0;
      }
      figcaption {
      }
    }
  }
  .body-text {
    border-left: 1px solid black;
    padding: 2rem 2rem;

    @media (min-width: 600px) {
      padding: 2rem 3rem;
    }
  }
`
