import React from 'react'
import styled from 'styled-components'
import {Fade} from 'react-awesome-reveal'

export default function QuoteBlock({cssModifier, quoteText, bylineText, bylineTextIsHtml = false, citationText = ''}) {
  return (
    <StyledComponent className={`quote-block ${cssModifier ? `quote-block--${cssModifier}` : ''}`}>
      <Fade>
        <div className="quote-block-inner">
          <blockquote dangerouslySetInnerHTML={{__html: quoteText}} />
          <figcaption>
            {bylineTextIsHtml ? <div dangerouslySetInnerHTML={{__html: bylineText}} /> : bylineText}
            {citationText && (
              <>
                , <cite>{citationText}</cite>
              </>
            )}
          </figcaption>
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.figure`
  margin: 0;

  .quote-block-inner {
    border-left: 1px solid black;
    margin: 0;
    padding: 2rem 2rem;

    @media (min-width: 600px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  blockquote {
    margin: 0 0 2rem 0;

    &::before {
      content: '“”';
      display: block;
      font-weight: 700;
      font-size: 4rem;
    }

    strong {
      font-weight: 600;
    }

    p {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
