import React from 'react'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import {Fade} from 'react-awesome-reveal'
import {graphql, useStaticQuery} from 'gatsby'
import Container from './container'
import PageHeading from './page-heading'
import QuoteBlockTwoCols from './../components/quote-block-two-cols'

export default function BookAConsultationSection({cssModifier}) {
  const data = useStaticQuery(graphql`
    {
      prismicBookAConsultationPage {
        data {
          your_consultation_heading {
            text
          }
          your_consultation_text_left {
            html
          }
          your_consultation_text_right {
            html
          }
          contact_form_suffix_text {
            html
          }
          intro_text {
            html
          }
          page_heading {
            text
          }
          quote_figure_caption {
            html
          }
          quote_text_left {
            html
          }
          quote_text_right {
            html
          }
        }
      }
    }
  `)

  const prismicData = {
    pageHeading: data.prismicBookAConsultationPage.data.page_heading.text,
    introTextHtml: data.prismicBookAConsultationPage.data.intro_text.html,
    contactFormSuffixTextHtml: data.prismicBookAConsultationPage.data.contact_form_suffix_text.html,
    yourConsultationHeadingText: data.prismicBookAConsultationPage.data.your_consultation_heading.text,
    yourConsultationTextLeftHtml: data.prismicBookAConsultationPage.data.your_consultation_text_left.html,
    yourConsultationTextRightHtml: data.prismicBookAConsultationPage.data.your_consultation_text_right.html,
    quoteTextLeftHtml: data.prismicBookAConsultationPage.data.quote_text_left.html,
    quoteTextRightHtml: data.prismicBookAConsultationPage.data.quote_text_right.html,
    quoteFigureCaptionHtml: data.prismicBookAConsultationPage.data.quote_figure_caption.html,
  }

  // console.log('BookAConsultationSection prismicData:', prismicData)

  return (
    <StyledComponent
      id={kebabCase(prismicData.pageHeading)}
      className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}
    >
      <PageHeading heading={prismicData.pageHeading} />

      <Container type="wide" className="page-content page-content--1">
        <Fade>
          <div className="intro-text" dangerouslySetInnerHTML={{__html: prismicData.introTextHtml}} />
        </Fade>

        <StyledForm
          name="Raman Malhotra Website Contact Form"
          method="POST"
          action="/thanks/"
          data-netlify="true"
          netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="Raman Malhotra Website Contact Form" />
          <input type="hidden" name="bot-field" />

          <div className="form-grid">
            <div className="form-item form-item--name">
              <Fade>
                <label className="form-item__label" htmlFor="name">
                  Name *
                </label>
                <input className="form-item__input" type="text" id="name" name="name" autoComplete="name" required />
              </Fade>
            </div>
            <div className="form-item form-item--email">
              <Fade>
                <label className="form-item__label" htmlFor="email">
                  Email *
                </label>
                <input className="form-item__input" type="email" id="email" name="email" autoComplete="email" required />
              </Fade>
            </div>
            <div className="form-item form-item--telephone">
              <Fade>
                <label className="form-item__label" htmlFor="telephone">
                  Telephone
                </label>
                <input
                  className="form-item__input"
                  type="text"
                  id="telephone"
                  name="telephone"
                  autoComplete="tel"
                  placeholder="OPTIONAL"
                />
              </Fade>
            </div>
            <div className="form-item form-item--message">
              <Fade>
                <label className="form-item__label" htmlFor="message">
                  Message *
                </label>
                <textarea className="form-item__textarea" id="message" name="message" autoComplete="off" required />
              </Fade>
            </div>
            <div className="form-item form-item--submit">
              <Fade>
                <button type="submit" className="btn btn--primary">
                  Send
                </button>
              </Fade>
            </div>
            <div className="form-item form-item--suffix-text">
              <Fade>
                <div className="suffix-text" dangerouslySetInnerHTML={{__html: prismicData.contactFormSuffixTextHtml}} />
              </Fade>
            </div>
          </div>
        </StyledForm>
      </Container>
      <Container type="wide" className="page-content page-content--2">
        <Fade>
          <h3 className="your-consultation-heading">{prismicData.yourConsultationHeadingText}</h3>
        </Fade>
        <div className="your-consultation-text-wrapper">
          <div className="your-consultation-text-left">
            <Fade>
              <div dangerouslySetInnerHTML={{__html: prismicData.yourConsultationTextLeftHtml}} />
            </Fade>
          </div>
          <div className="your-consultation-text-right">
            <Fade>
              <div dangerouslySetInnerHTML={{__html: prismicData.yourConsultationTextRightHtml}} />
            </Fade>
          </div>
        </div>
      </Container>
      <Container type="wide" className="page-content page-content--3">
        <QuoteBlockTwoCols
          cssModifier="page-content-3-quote-block-two-cols"
          quoteTextCol1={prismicData.quoteTextLeftHtml}
          quoteTextCol2={prismicData.quoteTextRightHtml}
          bylineText={prismicData.quoteFigureCaptionHtml}
          bylineTextIsHtml={true}
        />
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  margin-top: 2rem;
  padding-bottom: 4rem;

  &:before {
    content: '';
    display: block;
    height: 9.85rem;
    margin-top: -9.85rem;
    width: 100%;
    pointer-events: none;

    @media (min-width: 1200px) {
      height: 9.85rem;
      margin-top: -9.85rem;
    }
  }

  .page-content {
    max-width: 70rem;

    @media (min-width: 1200px) {
      max-width: 147rem;
    }

    &--1 {
      margin-bottom: 4rem;

      @media (min-width: 700px) {
        margin-bottom: 8rem;
      }
    }

    &--2 {
      margin-bottom: 4rem;

      @media (min-width: 700px) {
        margin-bottom: 8rem;
      }
    }
  }

  .intro-text {
    margin-bottom: 4rem;
  }

  .your-consultation-heading {
    font-weight: 300;
    margin-bottom: 2rem;
  }

  .your-consultation-text-wrapper {
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
      flex-direction: row;
      gap: 6rem;
    }
  }

  .your-consultation-text-left {
    margin-bottom: 2rem;

    @media (min-width: 1200px) {
      width: 50%;
    }
  }

  .your-consultation-text-right {
    @media (min-width: 1200px) {
      width: 50%;
    }
  }
`

const StyledForm = styled.form`
  margin-bottom: 0;
  max-width: 104rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 1100px) {
    margin-bottom: 0;
  }

  .form-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2.5rem 0;
    grid-template-areas:
      'name '
      'email'
      'telephone'
      'message'
      'submit'
      'suffix-text';

    @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 10.1rem 10.1rem 4.2rem 1fr;
      gap: 2.5rem 6rem;
      grid-template-areas:
        'name email'
        'message telephone'
        'message submit'
        'message suffix-text';
    }
  }

  .form-item {
    @media (min-width: 1200px) {
      width: 100%;
    }

    &--name {
      grid-area: name;
    }

    &--email {
      grid-area: email;
    }

    &--telephone {
      grid-area: telephone;

      /* @media (min-width: 1200px) {
        max-height: 10.9rem;
      } */
    }

    &--message {
      grid-area: message;
    }

    &--submit {
      grid-area: submit;
    }

    &--suffix-text {
      grid-area: suffix-text;
    }

    &__label {
      border-bottom: 1px solid #000;
      display: block;
      font-weight: 700;
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      max-width: 30rem;
      padding-bottom: 1rem;
      text-transform: uppercase;
    }

    &__input {
      border: 0;
      background-color: #d9d9d9;
      font-size: 13.9pt;
    }

    &__textarea {
      border: 0;
      background-color: #d9d9d9;
      font-size: 13.9pt;
      height: 20.5rem;
      max-height: 70rem;
      min-height: 20rem;
      height: 21rem;
      resize: vertical;

      @media (min-width: 1200px) {
        min-height: 21rem;
      }
    }
  }

  button {
    font-weight: 700;
    min-width: 15rem;
  }
`
