import React from 'react'
import styled from 'styled-components'
import {Fade} from 'react-awesome-reveal'

export default function QuoteBlockTwoCols({
  cssModifier,
  quoteTextCol1,
  quoteTextCol2,
  bylineText,
  bylineTextIsHtml = false,
  citationText = '',
}) {
  return (
    <StyledComponent className={`quote-block quote-block--two-cols ${cssModifier ? `quote-block--${cssModifier}` : ''}`}>
      <Fade>
        <div className="quote-block-inner">
          <blockquote>
            <div className="blockquote-inner">
              {quoteTextCol1 && (
                <div className="blockquote-col blockquote-col--1" dangerouslySetInnerHTML={{__html: quoteTextCol1}} />
              )}
              {quoteTextCol2 && (
                <div className="blockquote-col blockquote-col--2" dangerouslySetInnerHTML={{__html: quoteTextCol2}} />
              )}
            </div>
          </blockquote>
          <figcaption>
            {bylineTextIsHtml ? <div dangerouslySetInnerHTML={{__html: bylineText}} /> : bylineText}
            {citationText && (
              <>
                , <cite>{citationText}</cite>
              </>
            )}
          </figcaption>
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.figure`
  margin: 0;

  .quote-block-inner {
    border-left: 1px solid black;
    margin: 0;
    padding: 2rem 2rem;

    @media (min-width: 600px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  blockquote {
    margin: 0 0 2rem 0;

    &::before {
      content: '“”';
      display: block;
      font-weight: 700;
      font-size: 4rem;
    }

    strong {
      font-weight: 600;
    }

    p {
      margin-bottom: 2rem;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .blockquote-inner {
    display: flex;
    flex-direction: column;

    @media (min-width: 1200px) {
      flex-direction: row;
      gap: 6rem;
    }
  }

  .blockquote-col {
    &--1 {
      margin-bottom: 2rem;

      @media (min-width: 1200px) {
        margin-bottom: 0;
        width: 50%;
      }
    }

    &--2 {
      @media (min-width: 1200px) {
        width: 50%;
      }
    }
  }
`
