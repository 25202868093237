import React from 'react'
import styled from 'styled-components'
import {Fade} from 'react-awesome-reveal'

export default function TextBlock({children, cssModifier}) {
  return (
    <StyledComponent className={`text-block ${cssModifier ? `text-block--${cssModifier}` : ''}`}>
      <Fade>
        <div className="text-block-inner">{children}</div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  .text-block-inner {
    border-left: 1px solid black;
    margin: 0;
    padding: 2rem 2rem;

    @media (min-width: 600px) {
      padding-left: 3rem;
      padding-right: 3rem;
    }
  }

  blockquote {
    margin: 0 0 2rem 0;

    &::before {
      content: '“”';
      display: block;
      font-weight: 700;
      font-size: 4rem;
    }

    strong {
      font-weight: 600;
    }
  }
`
