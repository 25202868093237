import React from 'react'
import styled from 'styled-components'

export default function ArrowLink({label, url, cssModifier, isDownloadLink = false}) {
  return (
    <StyledComponent className={`arrow-link ${cssModifier ? `arrow-link--${cssModifier}` : ''}`}>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        download={isDownloadLink}
        dangerouslySetInnerHTML={{__html: label}}
      />
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  margin: 0 auto;
  max-width: 120rem;
  width: 100%;

  /* @media (min-width: 1000px) {
    margin-bottom: 4rem;
  } */

  a {
    background-color: transparent;
    border: 0;
    display: inline-block;
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0;
    max-width: 47rem;
    padding: 1.2rem 1.2rem 1.2rem 3rem;
    position: relative;
    text-align: left;
    text-transform: uppercase;
    z-index: 1;

    &::before {
      animation: arrowLinkArrowAnimation 4s linear 0s infinite normal none;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 99.6 115'%3E%3Cpath fill='%236b6b6b' d='M100 58L0 0v115z'/%3E%3C/svg%3E");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 2.2rem auto;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      transition: opacity 0.2s linear;
      width: 2.2rem;
    }

    &::after {
      background-color: #4e4e4e;
      bottom: 0;
      content: '';
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      right: 0;
      transition: transform 0.2s ease-in-out;
      transform-origin: left;
      transform: scaleX(0);
      width: 100%;
    }

    &:hover {
      &::before {
        opacity: 1;
      }
      &::after {
        transform: scaleX(1);
      }
    }

    @media (min-width: 640px) {
      font-size: 1.6rem;
      max-width: 55rem;
    }
  }
`
