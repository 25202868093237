import React, { Component } from 'react'
import styled from 'styled-components'
import { Fade } from 'react-awesome-reveal'
import Slider from 'react-slick'
import { Link as ScrollLink } from 'react-scroll'
import { graphql, useStaticQuery } from 'gatsby'

import { HOME_PAGE_SCROLL_DOWN_DURATION_MS } from './../constants/global-constants'
import { removeWrappingParagraphTag } from './../helper-functions/remove-wrapping-paragraph-tag'
import DownArrowSvg from './down-arrow-svg'

// Documentation
// https://www.npmjs.com/package/react-slick
// https://github.com/akiran/react-slick
// https://react-slick.neostack.com/
// https://kenwheeler.github.io/slick/

export default function HeroCarousel() {
  const data = useStaticQuery(graphql`
    {
      prismicHomePage {
        data {
          home_page_main_heading {
            html
          }
          body_text_left {
            html
          }
          body_text_right {
            html
          }
          sub_heading_left {
            html
          }
          sub_heading_right {
            html
          }
          hero_image_carousel {
            hero_image_desktop {
              alt
              url(imgixParams: { q: 45, bri: -10 })
            }
            hero_image_mobile {
              alt
              url(imgixParams: { q: 45, bri: -10 })
            }
            hero_image_tablet {
              alt
              url(imgixParams: { q: 45, bri: -10 })
            }
          }
        }
      }
    }
  `)

  const prismicData = {
    heroCarouselAllImages: data.prismicHomePage.data.hero_image_carousel,
    homePageMainHeading: data.prismicHomePage.data.home_page_main_heading.html,
    subHeadingLeft: data.prismicHomePage.data.sub_heading_left.html,
    subHeadingRight: data.prismicHomePage.data.sub_heading_right.html,
    bodyTextLeft: data.prismicHomePage.data.body_text_left.html,
    bodyTextRight: data.prismicHomePage.data.body_text_right.html,
  }

  // console.log('HeroCarousel prismicData:', prismicData)

  const settings = {
    autoplay: true,
    autoplaySpeed: 4000,
    dots: false,
    fade: true,
    infinite: true,
    lazyLoad: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <StyledComponent id="hero-carousel" className="hero-carousel">
      <Fade>
        <Slider {...settings} className="slider-component">
          {prismicData.heroCarouselAllImages.map((item, index) => (
            <div className="slide-wrapper" key={`slide-wrapper-${index}`}>
              <picture key={`picture-${index}`}>
                <source srcSet={item.hero_image_desktop.url} media="(min-width: 1200px)" alt={item.hero_image_desktop.alt} />
                <source srcSet={item.hero_image_tablet.url} media="(min-width: 700px)" alt={item.hero_image_tablet.alt} />
                <img
                  src={item.hero_image_mobile.url}
                  alt={item.hero_image_mobile.html}
                  alt={item.hero_image_mobile.alt}
                  width="500"
                  height="900"
                />
              </picture>
            </div>
          ))}
        </Slider>
      </Fade>
      <div className="text-container-grid">
        <div className="main-heading">
          <Fade delay={1500}>
            <div className="main-heading-inner">
              <h1 dangerouslySetInnerHTML={{ __html: removeWrappingParagraphTag(prismicData.homePageMainHeading) }} />
            </div>
          </Fade>
        </div>
        <div className="sub-heading-left">
          <Fade delay={2000}>
            <div dangerouslySetInnerHTML={{ __html: prismicData.subHeadingLeft }} />
          </Fade>
        </div>
        <div className="sub-heading-right">
          <Fade delay={2000}>
            <div dangerouslySetInnerHTML={{ __html: prismicData.subHeadingRight }} />
          </Fade>
        </div>
        <div className="body-text-left">
          <Fade delay={2000}>
            <div dangerouslySetInnerHTML={{ __html: prismicData.bodyTextLeft }} />
          </Fade>
        </div>
        <div className="body-text-right">
          <Fade delay={2000}>
            <div dangerouslySetInnerHTML={{ __html: prismicData.bodyTextRight }} />
          </Fade>
        </div>
      </div>
      <div className="scroll-btn-wrapper">
        <Fade delay={3000}>
          <ScrollLink
            to="about"
            className="scroll-btn"
            spy={true}
            smooth={true}
            offset={0}
            duration={HOME_PAGE_SCROLL_DOWN_DURATION_MS}
          >
            <span className="sr-only">Scroll down</span>
            <DownArrowSvg className="down-arrow-svg" fill="#fff" />
          </ScrollLink>
        </Fade>
      </div>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  z-index: 1;
  overflow: hidden;
  height: calc(100vh - 8.644rem);
  margin-bottom: 3rem;
  position: relative;

  @media (min-width: 375px) {
    height: calc(100vh - 9.95rem);
  }

  .slide-wrapper {
    outline: 0;
    height: calc(100vh - 8.644rem);

    @media (min-width: 375px) {
      height: calc(100vh - 9.95rem);
    }

    &::after {
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
      bottom: 0;
      content: '';
      display: block;
      height: 7rem;
      height: 50%;
      pointer-events: none;
      position: absolute;
      width: 100%;
      z-index: 1;
    }

    img {
      height: calc(100vh - 8.644rem);
      object-fit: cover;
      width: 100%;

      @media (min-width: 375px) {
        height: calc(100vh - 9.95rem);
      }
    }
  }

  /* .slick-dots {
    bottom: 8rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 50%;
    transform: translateX(-50%);
    width: 13rem;
    width: auto;

    li {
      margin: 0;
      height: 4rem;
      width: 4rem;

      button {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        padding: 0;
        width: 100%;

        &::before {
          background-color: transparent;
          border-radius: 50%;
          border: 3px solid white;
          color: white;
          content: '';

          opacity: 1;
          position: static;
          height: 2.2rem;
          width: 2.2rem;
        }
      }

      &.slick-active {
        button {
          &::before {
            background-color: white;
          }
        }
      }
    }
  } */

  .slick-track {
    /* background-color: black; */
  }

  .text-container-grid {
    bottom: 0;
    color: white;
    display: grid;
    gap: 1rem 0;
    grid-auto-flow: row;
    grid-template-areas:
      'main-heading'
      'sub-heading-left'
      'sub-heading-right';
    grid-template-columns: 1fr;
    left: 50%;
    max-width: 120rem;
    padding: 0 2rem;
    position: absolute;
    transform: translateX(-50%);
    width: 100%;
    bottom: 9rem;
    /* border: 1px solid yellow; */

    @media (min-width: 700px) {
      gap: 1rem 7rem;
      grid-template-areas:
        'main-heading main-heading'
        'sub-heading-left sub-heading-right'
        'body-text-left body-text-right';
      grid-template-columns: 1fr 1fr;
    }

    > * {
      /* border: 1px solid yellow; */
    }
  }

  .main-heading {
    grid-area: main-heading;
  }

  .main-heading-inner {
    padding-bottom: 1rem;
    position: relative;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      background-color: white;
      width: 22rem;
      height: 0.1rem;
      bottom: 0;
    }

    &::before {
      left: 0;

      @media (min-width: 400px) {
        width: 30rem;
      }
      @media (min-width: 800px) {
        width: 33rem;
      }
      @media (min-width: 900px) {
        width: 38rem;
      }
    }

    &::after {
      display: none;
      right: 0;

      @media (min-width: 700px) {
        display: block;
        width: 30rem;
      }
      @media (min-width: 800px) {
        width: 33rem;
      }
      @media (min-width: 900px) {
        width: 38rem;
      }
    }

    h1 {
      color: white;
      font-size: 3.2rem;
      font-weight: 300;
      line-height: 1;
      margin: 0;
      max-width: 29rem;
      text-transform: uppercase;

      @media (min-width: 400px) {
        font-size: 4.25rem;
        max-width: 35rem;
      }
      @media (min-width: 500px) {
        font-size: 4.25rem;
        max-width: 50rem;
      }
      @media (min-width: 700px) {
        font-size: 4.85rem;
        max-width: 50rem;
      }

      strong {
        font-size: 4.7rem;

        @media (min-width: 400px) {
          font-size: 6.3rem;
        }
        @media (min-width: 500px) {
          font-size: 6.3rem;
        }
        @media (min-width: 700px) {
          font-size: 7.2rem;
        }
      }
    }
  }

  .sub-heading-left {
    grid-area: sub-heading-left;

    @media (min-width: 400px) {
      font-size: 1.6rem;
    }
    @media (min-width: 700px) {
      font-size: 1.3rem;
    }
    @media (min-width: 800px) {
      font-size: 1.4rem;
    }
    @media (min-width: 900px) {
      font-size: 1.6rem;
    }
  }

  .sub-heading-right {
    font-size: 1.2rem;
    grid-area: sub-heading-right;
    text-align: left;

    @media (min-width: 400px) {
      font-size: 1.6rem;
    }
    @media (min-width: 700px) {
      font-size: 1.3rem;
      text-align: right;
    }
    @media (min-width: 800px) {
      font-size: 1.4rem;
    }
    @media (min-width: 900px) {
      font-size: 1.6rem;
    }
  }

  .body-text-left {
    display: none;
    grid-area: body-text-left;

    @media (min-width: 700px) {
      display: block;
      font-size: 1.3rem;
    }
    @media (min-width: 800px) {
      font-size: 1.4rem;
    }
    @media (min-width: 900px) {
      font-size: 1.6rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding-left: 3rem;

      li {
        margin-bottom: 0.6rem;
        position: relative;

        &::before {
          background-color: white;
          content: '';
          display: block;
          height: 0.1rem;
          left: -1.2rem;
          position: absolute;
          top: 0.9rem;
          width: 0.6rem;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .body-text-right {
    display: none;
    grid-area: body-text-right;
    text-align: right;

    @media (min-width: 700px) {
      display: block;
      font-size: 1.3rem;
    }
    @media (min-width: 800px) {
      font-size: 1.4rem;
    }
    @media (min-width: 900px) {
      font-size: 1.6rem;
    }

    p {
      margin: 0;
    }
  }

  .scroll-btn-wrapper {
    animation: scrollDownArrowAnimation 3s linear 0s infinite normal none;
    bottom: 3.5rem;
    display: block;
    height: auto;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 5.5rem;
    z-index: 10;

    @media (min-width: 768px) {
      bottom: 3.5rem;
    }

    @media (min-width: 900px) {
      bottom: 3.5rem;
    }
  }
`
