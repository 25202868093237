import React from 'react'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import { Fade } from 'react-awesome-reveal'
import { graphql, useStaticQuery } from 'gatsby'
import Container from './container'
import PageHeading from './page-heading'
import TextBlock from './text-block'
import QuoteBlock from './quote-block'
import FiveStars from './five-stars'

export default function AboutSection({ cssModifier }) {
  const data = useStaticQuery(graphql`
    {
      prismicAboutPage {
        data {
          intro_text {
            html
          }
          body_text {
            html
          }
          main_photo {
            url(imgixParams: { q: 45, sat: -100 })
            alt
          }
          photo_figure_caption {
            html
          }
          page_heading {
            text
          }
          awards_heading {
            text
          }
          awards_list {
            html
          }
          bottom_quote_left {
            html
          }
          bottom_quote_left_figure_caption {
            html
          }
          bottom_quote_right {
            html
          }
          bottom_quote_right_figure_caption {
            html
          }
          quote_1 {
            html
          }
          quote_1_figure_caption {
            html
          }
          quote_2 {
            html
          }
          quote_2_figure_caption {
            html
          }
          quote_3 {
            html
          }
          quote_3_figure_caption {
            html
          }
          star_quotes {
            star_quote {
              text
            }
          }
          star_quotes_background_image_desktop {
            url(imgixParams: { bri: -10 })
          }
          star_quotes_background_image_mobile {
            url(imgixParams: { bri: -10 })
          }
          star_quotes_background_image_tablet {
            url(imgixParams: { bri: -10 })
          }
          tatler_logo {
            alt
            url
          }
          tatler_sub_heading {
            text
          }
          pdf_links_heading {
            text
          }
          pdf_links {
            pdf_link_text {
              text
            }
            pdf_file {
              url
            }
          }
          last_quote {
            html
          }
          last_quote_figure_caption {
            html
          }
        }
      }
    }
  `)

  const prismicData = {
    pageHeading: data.prismicAboutPage.data.page_heading.text,
    introTextHtml: data.prismicAboutPage.data.intro_text.html,
    bodyTextHtml: data.prismicAboutPage.data.body_text.html,
    mainPhotoUrl: data.prismicAboutPage.data.main_photo.url,
    mainPhotoAlt: data.prismicAboutPage.data.main_photo.alt,
    photoFigureCaptionHtml: data.prismicAboutPage.data.photo_figure_caption.html,
    quote1html: data.prismicAboutPage.data.quote_1.html,
    quote1figureCaption: data.prismicAboutPage.data.quote_1_figure_caption.html,
    awardsHeadingText: data.prismicAboutPage.data.awards_heading.text,
    awardsListHtml: data.prismicAboutPage.data.awards_list.html,
    tatlerLogoUrl: data.prismicAboutPage.data.tatler_logo.url,
    tatlerLogoAlt: data.prismicAboutPage.data.tatler_logo.alt,
    tatlerSubHeadingText: data.prismicAboutPage.data.tatler_sub_heading.text,
    quote2html: data.prismicAboutPage.data.quote_2.html,
    quote2figureCaption: data.prismicAboutPage.data.quote_2_figure_caption.html,
    quote3html: data.prismicAboutPage.data.quote_3.html,
    quote3figureCaption: data.prismicAboutPage.data.quote_3_figure_caption.html,
    starQuotesBackgroundImageMobileUrl: data.prismicAboutPage.data.star_quotes_background_image_mobile.url,
    starQuotesBackgroundImageTabletUrl: data.prismicAboutPage.data.star_quotes_background_image_tablet.url,
    starQuotesBackgroundImageDesktopUrl: data.prismicAboutPage.data.star_quotes_background_image_desktop.url,
    starQuotes: data.prismicAboutPage.data.star_quotes,
    bottomQuoteLeftHtml: data.prismicAboutPage.data.bottom_quote_left.html,
    bottomQuoteLeftFigureCaptionHtml: data.prismicAboutPage.data.bottom_quote_left_figure_caption.html,
    bottomQuoteRightHtml: data.prismicAboutPage.data.bottom_quote_right.html,
    bottomQuoteRightFigureCaptionHtml: data.prismicAboutPage.data.bottom_quote_right_figure_caption.html,
    pdfLinksHeadingText: data.prismicAboutPage.data.pdf_links_heading.text,
    pdfLinksArray: data.prismicAboutPage.data.pdf_links,
    lastQuoteHtml: data.prismicAboutPage.data.last_quote.html,
    lastQuoteFigureCaptionHtml: data.prismicAboutPage.data.last_quote_figure_caption.html,
  }

  // console.log('AboutSection prismicData:', prismicData)

  return (
    <StyledComponent
      id={kebabCase(prismicData.pageHeading)}
      className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}
    >
      <PageHeading heading={prismicData.pageHeading} />

      <Container type="wide" className="page-content page-content--1">
        <div className="intro-text text-large">
          <Fade>
            <div dangerouslySetInnerHTML={{ __html: prismicData.introTextHtml }} />
          </Fade>
        </div>
        <TextBlock className="body-text" cssModifier="body-text">
          <div dangerouslySetInnerHTML={{ __html: prismicData.bodyTextHtml }} />
        </TextBlock>
        <TextBlock cssModifier="main-photo-wrapper">
          <figure>
            <img src={prismicData.mainPhotoUrl} alt={prismicData.mainPhotoAlt} width="399" height="479" />
            <figcaption dangerouslySetInnerHTML={{ __html: prismicData.photoFigureCaptionHtml }} />
          </figure>
        </TextBlock>
      </Container>
      <Container type="wide" className="page-content page-content--2">
        <QuoteBlock
          cssModifier="page-content-2-quote-block"
          quoteText={prismicData.quote1html}
          bylineText={prismicData.quote1figureCaption}
          bylineTextIsHtml={true}
        />
        <Fade>
          <div className="awards-wrapper">
            <h3 className="awards-heading">{prismicData.awardsHeadingText}</h3>
            <div className="awards-list" dangerouslySetInnerHTML={{ __html: prismicData.awardsListHtml }} />
          </div>
        </Fade>
      </Container>
      <Container type="wide" className="page-content page-content--3">
        <Fade>
          <figure className="tatler-logo-wrapper">
            <img
              className="tatler-logo-img"
              src={prismicData.tatlerLogoUrl}
              alt={prismicData.tatlerLogoAlt}
              width="280"
              height="80"
            />
            <figcaption className="tatler-logo-figcaption">{prismicData.tatlerSubHeadingText}</figcaption>
          </figure>
        </Fade>
      </Container>
      <Container type="wide" className="page-content page-content--4">
        <QuoteBlock
          cssModifier="page-content-4-quote-block-left"
          quoteText={prismicData.quote2html}
          bylineText={prismicData.quote2figureCaption}
          bylineTextIsHtml={true}
        />
        <QuoteBlock
          cssModifier="page-content-4-quote-block-right"
          quoteText={prismicData.quote3html}
          bylineText={prismicData.quote3figureCaption}
          bylineTextIsHtml={true}
        />
      </Container>
      <StyledStarQuotesDiv
        className="star-quotes-wrapper"
        mobileImageUrl={prismicData.starQuotesBackgroundImageMobileUrl}
        tabletImageUrl={prismicData.starQuotesBackgroundImageTabletUrl}
        desktopImageUrl={prismicData.starQuotesBackgroundImageDesktopUrl}
      >
        <Fade>
          <Container type="wide" className="page-content page-content--5">
            {prismicData.starQuotes.map((item, index) => (
              <div className="star-quote-wrapper" key={`star-quote-${index}`}>
                <FiveStars className="stars" />
                <blockquote>{item.star_quote.text}</blockquote>
              </div>
            ))}
          </Container>
        </Fade>
      </StyledStarQuotesDiv>
      <Container type="wide" className="page-content page-content--6">
        <QuoteBlock
          cssModifier="page-content-6-quote-block-left"
          quoteText={prismicData.bottomQuoteLeftHtml}
          bylineText={prismicData.bottomQuoteLeftFigureCaptionHtml}
          bylineTextIsHtml={true}
        />
        <QuoteBlock
          cssModifier="page-content-6-quote-block-right"
          quoteText={prismicData.bottomQuoteRightHtml}
          bylineText={prismicData.bottomQuoteRightFigureCaptionHtml}
          bylineTextIsHtml={true}
        />
      </Container>
      <Container type="wide" className="page-content page-content--7">
        <div className="page-content-7-block-left">
          <Fade>
            <h3 className="pdf-links-heading">{prismicData.pdfLinksHeadingText}</h3>
          </Fade>
          <Fade>
            <div className="pdf-links-wrapper">
              {prismicData.pdfLinksArray.map((item, index) => (
                <a
                  className="pdf-link"
                  key={`pdf-link-${index}`}
                  href={item.pdf_file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.pdf_link_text.text}
                </a>
              ))}
            </div>
          </Fade>
        </div>
        <QuoteBlock
          cssModifier="page-content-7-quote-block-right"
          quoteText={prismicData.lastQuoteHtml}
          bylineText={prismicData.lastQuoteFigureCaptionHtml}
          bylineTextIsHtml={true}
        />
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  padding-bottom: 10rem;

  &:before {
    content: '';
    display: block;
    height: 9.85rem;
    margin-top: -9.85rem;
    width: 100%;
    pointer-events: none;

    @media (min-width: 1200px) {
      height: 9.85rem;
      margin-top: -9.85rem;
    }
  }

  .row {
    display: flex;
    flex-direction: column;
    margin: 0 auto 4rem auto;
    max-width: 120rem;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }

    &--1 {
      margin-bottom: 2rem;

      @media (min-width: 1000px) {
        display: flex;
        flex-direction: row;
        margin-bottom: 4rem;
      }
    }

    &--2 {
      @media (min-width: 768px) {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .quote-block {
    &--quote {
      @media (min-width: 1000px) {
        align-self: center;
        margin-left: 3rem;
      }
    }

    &--page-content-2-quote-block {
      margin-bottom: 2rem;

      blockquote,
      figcaption {
        @media (min-width: 1200px) {
          max-width: 43rem;
        }
      }
    }

    &--page-content-4-quote-block-left {
      margin-bottom: 2rem;

      @media (min-width: 1200px) {
        margin-bottom: 0;
      }

      .quote-block-inner {
        @media (min-width: 1200px) {
          height: 100%;
        }
      }
    }

    &--page-content-6-quote-block-left {
      margin-bottom: 2rem;
    }
  }

  .text-block {
    &--body-text {
      height: 100%;
      max-width: 76rem;
    }

    &--main-photo-wrapper {
      img {
        margin-bottom: 1rem;
        max-width: 40rem;
      }
      figcaption {
        p {
          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .intro-text {
    margin-bottom: 2rem;
    max-width: 80rem;

    @media (min-width: 1200px) {
      max-width: 93rem;
    }
  }

  .page-content {
    max-width: 70rem;

    @media (min-width: 1200px) {
      max-width: 147rem;
    }

    &--1 {
      margin-bottom: 2rem;

      @media (min-width: 700px) {
        margin-bottom: 4rem;
      }

      @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 46rem;
        gap: 0px 0px;
        grid-template-areas:
          'intro-text intro-text'
          'body-text photo';

        .intro-text {
          grid-area: intro-text;
        }
        .text-block--body-text {
          grid-area: body-text;
        }
        .text-block--main-photo-wrapper {
          grid-area: photo;
        }
      }
    }
    &--2 {
      margin-bottom: 2rem;

      @media (min-width: 700px) {
        margin-bottom: 4rem;
      }

      @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 64rem;
      }
    }
    &--3 {
      margin-bottom: 2rem;

      @media (min-width: 700px) {
        margin-bottom: 4rem;
      }
    }
    &--4 {
      margin-bottom: 2rem;

      @media (min-width: 700px) {
        margin-bottom: 4rem;
      }

      @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 46rem;
        gap: 0px 0px;
      }
    }
    /* &--5 {
      margin-bottom: 2rem;

      @media (min-width: 700px) {
        margin-bottom: 4rem;
      }
    } */
    &--6 {
      margin-bottom: 4rem;

      @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0px 0px;
      }

      .quote-block-inner {
        @media (min-width: 1200px) {
          height: 100%;
        }
      }
    }

    &--7 {
      margin-bottom: 0;

      @media (min-width: 1200px) {
        display: grid;
        grid-template-columns: 62% 38%;
        gap: 0px 0px;
      }

      .quote-block-inner {
        @media (min-width: 1200px) {
          height: 100%;
        }
      }
    }
  }

  .awards-wrapper {
    .awards-heading {
      margin-bottom: 1rem;
    }

    .awards-list {
      padding: 2rem 0;
      position: relative;

      &::before,
      &::after {
        background-color: #262626;
        content: '';
        display: block;
        height: 0.1rem;
        left: 0;
        position: absolute;
        width: 6rem;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }

      ul {
        margin: 0 0 2rem 0;
        padding-left: 1.7rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      li {
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .tatler-logo-wrapper {
    img {
      margin-bottom: 1rem;
      max-width: 28rem;
    }
    figcaption {
      @media (min-width: 1200px) {
        font-size: 1.8rem;
      }
    }
  }

  .star-quotes-wrapper {
    margin-bottom: 2rem;

    @media (min-width: 700px) {
      margin-bottom: 4rem;
    }
  }

  .page-content-7-block-left {
    margin-bottom: 4rem;

    @media (min-width: 1200px) {
      margin-bottom: 0;
      margin-right: 6rem;
    }
  }

  .pdf-links-heading {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
    padding-left: 3rem;
    position: relative;

    @media (min-width: 400px) {
      font-size: 1.8rem;
    }
    @media (min-width: 550px) {
      font-size: 2rem;
    }
    @media (min-width: 600px) {
      font-size: 2.2rem;
    }

    &::before {
      animation: arrowLinkArrowAnimation 4s linear 0s infinite normal none;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 99.6 115'%3E%3Cpath fill='%236b6b6b' d='M100 58L0 0v115z'/%3E%3C/svg%3E");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 2.2rem auto;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      transition: opacity 0.2s linear;
      width: 2.2rem;
    }
  }

  .pdf-links-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.6rem 0px;

    @media (min-width: 630px) {
      grid-template-columns: 1fr 1fr;
      gap: 1.6rem 1.6rem;
      margin-left: 3rem;
    }

    @media (min-width: 1200px) {
      gap: 2.5rem 2.5rem;
    }
  }

  .pdf-link {
    background-color: #d9d9d9;
    font-weight: 700;
    margin: 0;
    padding: 1rem 1rem;
    text-align: center;
    text-transform: uppercase;
    transition: background-color 0.3s linear;

    &:hover {
      background-color: #b1b1b1;
    }

    @media (min-width: 1200px) {
      padding: 1.8rem 1rem;
    }
  }

  .quote-block--page-content-7-quote-block-right {
    figcaption {
      max-width: 33rem;

      @media (min-width: 600px) {
        max-width: 38rem;
      }

      @media (min-width: 1200px) {
        max-width: none;
      }
    }
  }
`

const StyledStarQuotesDiv = styled.div`
  background-image: url(${props => props.mobileImageUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 42rem;
  padding: 6rem 2rem;

  @media (min-width: 700px) {
    background-image: url(${props => props.tabletImageUrl});
  }

  @media (min-width: 1200px) {
    background-image: url(${props => props.desktopImageUrl});
  }

  .page-content {
    display: grid;
    gap: 5rem 2rem;
    grid-template-columns: 1fr;

    @media (min-width: 700px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .star-quote-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    display: none;

    &:nth-child(-n + 4) {
      display: flex;
    }

    @media (min-width: 700px) {
      &:nth-child(-n + 6) {
        display: flex;
      }
    }

    @media (min-width: 1200px) {
      display: flex;
    }
  }

  .stars {
    margin-bottom: 1rem;
    width: 12rem;
  }

  blockquote {
    color: white;
    font-weight: 600;
    margin: 0;
    text-align: center;
  }
`
