import React from 'react'
import styled from 'styled-components'
import {Fade} from 'react-awesome-reveal'
import Container from './container'

export default function PageHeading({heading}) {
  return (
    <StyledComponent className="page-heading">
      <Fade>
        <Container type="wide" className="page-heading-container">
          <h2 className="page-heading">{heading}</h2>
        </Container>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.div`
  margin-bottom: 2rem;

  @media (min-width: 375px) {
    margin-bottom: 2.5rem;
  }

  @media (min-width: 700px) {
    margin-bottom: 3rem;
  }

  @media (min-width: 1000px) {
    margin-bottom: 4rem;
  }

  @media (min-width: 1200px) {
    padding-top: 0;
  }

  .page-heading-container {
    @media (min-width: 1200px) {
      padding: 0 5rem;
    }
  }

  h2 {
    background-color: #262626;
    color: #fff;
    display: inline-block;
    font-size: 1.9rem;
    font-weight: 200;
    line-height: 1.2;
    margin: 0 0 0 -2rem;
    min-width: 28.3rem;
    padding: 1.2rem 2.5rem;
    text-transform: uppercase;

    @media (min-width: 375px) {
      font-size: 2.3rem;
      min-width: 32rem;
    }

    @media (min-width: 1200px) {
      font-size: 2.7rem;
      margin-left: -5rem;
      min-width: 39rem;
    }
  }
}`
