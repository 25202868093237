import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {Fade} from 'react-awesome-reveal'
import styled from 'styled-components'

export default function EmergencyMessage() {
  const data = useStaticQuery(graphql`
    {
      prismicEmergencyMessage {
        data {
          message_heading {
            text
          }
          message_body {
            html
          }
          call_to_action_label {
            text
          }
          call_to_action_link {
            url
          }
        }
      }
    }
  `)

  const prismicData = {
    messageHeading: data.prismicEmergencyMessage.data.message_heading.text,
    messageBody: data.prismicEmergencyMessage.data.message_body.html,
    callToActionLabelText: data.prismicEmergencyMessage.data.call_to_action_label.text,
    callToActionLinkUrl: data.prismicEmergencyMessage.data.call_to_action_link.url,
  }

  // console.log('EmergencyMessage prismicData:', prismicData)

  return (
    <StyledComponent>
      <Fade delay={3500}>
        <div className="message-wrapper">
          {prismicData.messageHeading && <h3 className="message-heading">{prismicData.messageHeading}</h3>}
          {prismicData.messageBody && (
            <div className="message-body" dangerouslySetInnerHTML={{__html: prismicData.messageBody}} />
          )}
          {prismicData.callToActionLinkUrl && prismicData.callToActionLabelText && (
            <a className="cta-link" href={prismicData.callToActionLinkUrl} target="_blank" rel="noopener noreferrer">
              {prismicData.callToActionLabelText}
            </a>
          )}
        </div>
      </Fade>
    </StyledComponent>
  )
}

const StyledComponent = styled.aside`
  left: 50%;
  max-width: 50rem;
  position: absolute;
  top: 11rem;
  transform: translateX(-50%);
  width: calc(100% - 4rem);
  z-index: 10;

  @media (min-width: 375px) {
    top: 12rem;
  }

  .message-wrapper {
    background-color: white;
    border-radius: 0.6rem;
    box-shadow: 5px 5px 16px rgb(0 0 0 / 30%);
    font-size: 1.2rem;
    margin: 0rem;
    padding: 1rem;
  }

  .message-heading {
    font-weight: 600;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  .message-body {
    margin-bottom: 1rem;
  }

  .cta-link {
    background-color: #262626;
    border-radius: 0.4rem;
    color: white;
    display: block;
    padding: 0.5rem 0.5rem;
    text-align: center;
  }
`
