import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../components/layout'
import {
  ABOUT_SECTION_CSS_MODIFIER,
  // PROCEDURES_SECTION_CSS_MODIFIER,
  // CONDITIONS_SECTION_CSS_MODIFIER,
  PROCEDURES_AND_CONDITIONS_SECTION_CSS_MODIFIER,
  TESTIMONIALS_SECTION_CSS_MODIFIER,
  CONTACT_DETAILS_SECTION_CSS_MODIFIER,
  BOOK_A_CONSULTATION_SECTION_CSS_MODIFIER,
} from './../constants/global-constants'
import EmergencyMessage from '../components/emergency-message'
import HeroCarousel from './../components/hero-carousel'
import AboutSection from './../components/about-section'
// import ProceduresSection from '../components/procedures-section'
// import ConditionsSection from '../components/conditions-section'
import ProceduresAndConditionsSection from '../components/procedures-and-conditions-section'
import TestimonialsSection from '../components/testimonials-section'
import ContactDetailsSection from '../components/contact-details-section'
import BookAConsultationSection from '../components/book-a-consultation-section'

const IndexPage = props => {
  const [sectionObserved, setSectionObserved] = useState('')
  const { data } = props
  const prismicData = {
    siteDescription: data.prismicSiteMeta.data.site_description.text,
    showEmergencyMessage: data.prismicEmergencyMessage.data.show_emergency_message,
  }

  // console.log('IndexPage prismicData:', prismicData)

  useEffect(() => {
    const allSectionsNodeList = document.querySelectorAll('section')
    const intersectionObserver = new IntersectionObserver(
      function (entries, intersectionObserver) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setSectionObserved(entry.target.id)
          }
        })
      },
      {
        rootMargin: '-50% 0px -50% 0px',
      }
    )
    allSectionsNodeList.forEach(section => {
      intersectionObserver.observe(section)
    })
  }, [])

  return (
    <Layout
      documentTitlePrefix=""
      isIndexPage={true}
      pageName="index"
      showScrollToTopBtn={true}
      sectionObserved={sectionObserved}
    >
      {prismicData.showEmergencyMessage && <EmergencyMessage />}
      <HeroCarousel />
      <AboutSection cssModifier={ABOUT_SECTION_CSS_MODIFIER} />
      {/* <ProceduresSection cssModifier={PROCEDURES_SECTION_CSS_MODIFIER} />
      <ConditionsSection cssModifier={CONDITIONS_SECTION_CSS_MODIFIER} /> */}
      <ProceduresAndConditionsSection cssModifier={PROCEDURES_AND_CONDITIONS_SECTION_CSS_MODIFIER} />
      <TestimonialsSection cssModifier={TESTIMONIALS_SECTION_CSS_MODIFIER} />
      <ContactDetailsSection cssModifier={CONTACT_DETAILS_SECTION_CSS_MODIFIER} />
      <BookAConsultationSection cssModifier={BOOK_A_CONSULTATION_SECTION_CSS_MODIFIER} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  {
    prismicSiteMeta {
      data {
        site_description {
          text
        }
      }
    }
    prismicEmergencyMessage {
      data {
        show_emergency_message
      }
    }
  }
`
