import React from 'react'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import { Fade } from 'react-awesome-reveal'
import { graphql, useStaticQuery } from 'gatsby'
import Container from './container'
import PageHeading from './page-heading'
import TextBlock from './text-block'
import QuoteBlock from './quote-block'
import ArrowLink from './arrow-link'
import { stripMarkup } from './../helper-functions/strip-markup'

export default function ProceduresAndConditionsSection({ cssModifier }) {
  const data = useStaticQuery(graphql`
    {
      prismicProceduresConditionsPage {
        data {
          page_heading {
            text
          }
          intro_body_text_left {
            html
          }
          intro_body_text_right {
            html
          }
          intro_heading {
            text
          }
          pdf_links {
            pdf_body_text {
              html
            }
            pdf_file {
              url
            }
            pdf_link_text {
              text
            }
          }
          pdf_links_heading {
            text
          }
          pdf_preview_cover_image_1 {
            alt
            url
          }
          pdf_preview_cover_image_2 {
            alt
            url
          }
          pdf_preview_file_1 {
            url
          }
          pdf_preview_file_2 {
            url
          }
        }
      }
    }
  `)

  const prismicData = {
    pageHeading: data.prismicProceduresConditionsPage.data.page_heading.text,
    introHeadingText: data.prismicProceduresConditionsPage.data.intro_heading.text,
    introBodyTextLeftHtml: data.prismicProceduresConditionsPage.data.intro_body_text_left.html,
    introBodyTextRightHtml: data.prismicProceduresConditionsPage.data.intro_body_text_right.html,
    pdfLinksHeadingText: data.prismicProceduresConditionsPage.data.pdf_links_heading.text,
    pdfLinksArray: data.prismicProceduresConditionsPage.data.pdf_links,
    pdfPreviewCoverImage1url: data.prismicProceduresConditionsPage.data.pdf_preview_cover_image_1.url,
    pdfPreviewCoverImage1alt: data.prismicProceduresConditionsPage.data.pdf_preview_cover_image_1.alt,
    pdfPreviewFile1url: data.prismicProceduresConditionsPage.data.pdf_preview_file_1.url,
    pdfPreviewCoverImage2url: data.prismicProceduresConditionsPage.data.pdf_preview_cover_image_2.url,
    pdfPreviewCoverImage2alt: data.prismicProceduresConditionsPage.data.pdf_preview_cover_image_2.alt,
    pdfPreviewFile2url: data.prismicProceduresConditionsPage.data.pdf_preview_file_2.url,
  }

  // console.log('ProceduresAndConditionsSection prismicData:', prismicData)

  return (
    <StyledComponent
      id={kebabCase(prismicData.pageHeading)}
      className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}
    >
      <PageHeading heading={prismicData.pageHeading} />

      <Fade>
        <Container type="wide" className="page-content page-content--1">
          <h3 className="intro-heading">{prismicData.introHeadingText}</h3>
          <div className="intro-body-text-wrapper">
            <div className="intro-body-text-left" dangerouslySetInnerHTML={{ __html: prismicData.introBodyTextLeftHtml }} />
            <div className="intro-body-text-right" dangerouslySetInnerHTML={{ __html: prismicData.introBodyTextRightHtml }} />
          </div>
        </Container>
      </Fade>

      <Container type="wide" className="page-content page-content--2">
        <Fade>
          <h3 className="pdf-links-heading">{prismicData.pdfLinksHeadingText}</h3>
        </Fade>
        <div className="pdf-links-wrapper">
          {prismicData.pdfLinksArray.map((item, index) => (
            <Fade>
              <div className="pdf-link-wrapper">
                <a
                  className="pdf-link"
                  key={`pdf-link-${index}`}
                  href={item.pdf_file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.pdf_link_text.text}
                </a>
                {stripMarkup(item.pdf_body_text.html) && (
                  <div className="pdf-link-body-text-wrapper" dangerouslySetInnerHTML={{ __html: item.pdf_body_text.html }} />
                )}
              </div>
            </Fade>
          ))}
        </div>
      </Container>

      <Container type="wide" className="page-content page-content--3">
        <Fade>
          <a
            className="preview-file-link preview-file-link--1"
            href={prismicData.pdfPreviewFile1url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="preview-file-image"
              src={prismicData.pdfPreviewCoverImage1url}
              alt={prismicData.pdfPreviewCoverImage1alt}
            />
          </a>
        </Fade>
        <Fade>
          <a
            className="preview-file-link preview-file-link--2"
            href={prismicData.pdfPreviewFile2url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="preview-file-image"
              src={prismicData.pdfPreviewCoverImage2url}
              alt={prismicData.pdfPreviewCoverImage2alt}
            />
          </a>
        </Fade>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  padding-bottom: 10rem;

  &:before {
    content: '';
    display: block;
    height: 9.85rem;
    margin-top: -9.85rem;
    width: 100%;
    pointer-events: none;

    @media (min-width: 1200px) {
      height: 9.85rem;
      margin-top: -9.85rem;
    }
  }

  .page-content {
    margin-bottom: 3rem;
    max-width: 70rem;

    @media (min-width: 1200px) {
      max-width: 147rem;
    }

    &--3 {
      @media (min-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0 3rem;
      }

      @media (min-width: 1200px) {
        gap: 0 6.5rem;
      }
    }
  }

  .intro-heading {
    font-weight: 300;
    margin-bottom: 1.6rem;

    @media (min-width: 1200px) {
      font-size: 2.2rem;
    }
  }

  .intro-body-text-wrapper {
    display: block;

    @media (min-width: 600px) {
      padding-left: 3rem;
    }

    @media (min-width: 1200px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 0 7rem;
    }
  }

  .intro-body-text-left {
    margin-bottom: 1.6rem;
  }

  .pdf-links-heading {
    font-size: 1.6rem;
    margin-bottom: 1.6rem;
    padding-left: 3rem;
    position: relative;

    @media (min-width: 400px) {
      font-size: 1.8rem;
    }
    @media (min-width: 550px) {
      font-size: 2rem;
    }
    @media (min-width: 600px) {
      font-size: 2.2rem;
    }

    &::before {
      animation: arrowLinkArrowAnimation 4s linear 0s infinite normal none;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 99.6 115'%3E%3Cpath fill='%236b6b6b' d='M100 58L0 0v115z'/%3E%3C/svg%3E");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 2.2rem auto;
      content: '';
      display: block;
      height: 100%;
      left: 0;
      opacity: 0.5;
      position: absolute;
      top: 0;
      transition: opacity 0.2s linear;
      width: 2.2rem;
    }
  }

  .pdf-links-wrapper {
    column-count: 1;
    padding: 0 0.6rem;

    @media (min-width: 1200px) {
      column-count: 2;
      column-gap: 6.7rem;
    }

    .pdf-link {
      display: inline-block;
      margin: 0 0 1.6rem 0;
      padding-left: 2.4rem;
      position: relative;

      &::before {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 99.6 115'%3E%3Cpath fill='%236b6b6b' d='M100 58L0 0v115z'/%3E%3C/svg%3E");
        background-position: left 1px;
        background-repeat: no-repeat;
        background-size: 1.4rem auto;
        content: '';
        display: block;
        height: 100%;
        left: 0;
        opacity: 0.25;
        position: absolute;
        top: 0;
        transition: opacity 0.2s linear;
        width: 1.4rem;
      }

      &::after {
        background-color: #4e4e4e;
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 2.4rem;
        position: absolute;
        right: 0;
        transition: transform 0.2s ease-in-out;
        transform-origin: left;
        transform: scaleX(0);
        width: calc(100% - 2.4rem);
      }

      &:hover {
        &::before {
          opacity: 1;
        }
        &::after {
          transform: scaleX(1);
        }
      }
    }

    .pdf-link-body-text-wrapper {
      margin: -1.2rem 0 1.6rem 1.6rem;
      padding-left: 2.4rem;

      @media (min-width: 600px) {
        margin-left: 1.6rem;
      }

      p {
        @media (min-width: 1200px) {
          line-height: 1.77;
        }
      }
    }
  }

  .preview-file-link {
    border: 2px solid #d9d9d9;
    display: block;

    &--1 {
      margin-bottom: 2rem;
    }
  }
`
