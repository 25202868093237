import React from 'react'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import { Fade } from 'react-awesome-reveal'
import { graphql, useStaticQuery } from 'gatsby'
import Container from './container'
import PageHeading from './page-heading'
import TextBlock from './../components/text-block'
import QuoteBlock from './../components/quote-block'
import ArrowLink from './arrow-link'
import FiveStars from './five-stars'

export default function TestimonialsSection({ cssModifier }) {
  const data = useStaticQuery(graphql`
    {
      prismicTestimonialsPage {
        data {
          page_heading {
            text
          }
          body_text {
            html
          }
          five_star_review_text {
            text
          }
          i_want_great_care_intro_text {
            html
          }
          i_want_great_care_link_label {
            text
          }
          i_want_great_care_url {
            url
          }
          quotes {
            quote_figure_caption {
              text
            }
            quote_text {
              text
            }
          }
          sub_heading {
            text
          }
        }
      }
    }
  `)

  const prismicData = {
    pageHeading: data.prismicTestimonialsPage.data.page_heading.text,
    bodyTextHtml: data.prismicTestimonialsPage.data.body_text.html,
    fiveStarReviewText: data.prismicTestimonialsPage.data.five_star_review_text.text,
    iWantGreatCareIntroTextHtml: data.prismicTestimonialsPage.data.i_want_great_care_intro_text.html,
    iWantGreatCareLinkLabelText: data.prismicTestimonialsPage.data.i_want_great_care_link_label.text,
    iWantGreatCareUrl: data.prismicTestimonialsPage.data.i_want_great_care_url.url,
    quotes: data.prismicTestimonialsPage.data.quotes,
    subHeadingText: data.prismicTestimonialsPage.data.sub_heading.text,
  }

  // console.log('TestimonialsSection prismicData:', prismicData)

  return (
    <StyledComponent
      id={kebabCase(prismicData.pageHeading)}
      className={`site-section ${cssModifier ? `site-section--${cssModifier}` : ''}`}
    >
      <PageHeading heading={prismicData.pageHeading} />

      <Container type="wide" className="page-content page-content--1">
        <Fade>
          <p className="sub-heading text-large" dangerouslySetInnerHTML={{ __html: prismicData.subHeadingText }} />
        </Fade>
        <Fade>
          <div className="body-text" dangerouslySetInnerHTML={{ __html: prismicData.bodyTextHtml }} />
        </Fade>
      </Container>
      <Container type="wide" className="page-content page-content--2">
        <div className="quotes-wrapper">
          {prismicData.quotes.map((item, index) => (
            <QuoteBlock
              key={`quote-${index}`}
              cssModifier="page-content-2-quote-block"
              quoteText={item.quote_text.text}
              bylineText={item.quote_figure_caption.text}
              bylineTextIsHtml={false}
            />
          ))}
        </div>
      </Container>
      <Container type="wide" className="page-content page-content--3">
        <Fade>
          <div className="i-want-great-care-wrapper">
            <div
              className="i-want-great-care-intro"
              dangerouslySetInnerHTML={{ __html: prismicData.iWantGreatCareIntroTextHtml }}
            />
            <ArrowLink
              cssModifier="i-want-great-care-link"
              label={prismicData.iWantGreatCareLinkLabelText}
              url={prismicData.iWantGreatCareUrl}
              isDownloadLink={false}
            />
            <div className="five-star-wrapper">
              <p className="five-star-reviews">{prismicData.fiveStarReviewText}</p>
              <FiveStars className="five-stars-svg" fill="#262626" />
            </div>
          </div>
        </Fade>
      </Container>
    </StyledComponent>
  )
}

const StyledComponent = styled.section`
  padding-bottom: 10rem;

  &:before {
    content: '';
    display: block;
    height: 9.85rem;
    margin-top: -9.85rem;
    width: 100%;
    pointer-events: none;

    @media (min-width: 1200px) {
      height: 9.85rem;
      margin-top: -9.85rem;
    }
  }

  .page-content {
    max-width: 70rem;

    @media (min-width: 1200px) {
      max-width: 147rem;
    }

    &--1 {
      margin-bottom: 2rem;

      @media (min-width: 700px) {
        margin-bottom: 4rem;
      }
    }

    &--2 {
      margin-bottom: 2rem;

      @media (min-width: 700px) {
        margin-bottom: 4rem;
      }
    }
  }

  .sub-heading {
    margin-bottom: 2rem;
  }

  .quotes-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: space-between;

    @media (min-width: 700px) {
      /* grid-template-columns: fit-content(36rem) fit-content(36rem); */
      grid-template-columns: 1fr 1fr;
      gap: 2rem 2rem;
    }

    @media (min-width: 1200px) {
      /* grid-template-columns: fit-content(36rem) fit-content(36rem) fit-content(36rem); */
      grid-template-columns: 1fr 1fr 1fr;
      gap: 2rem 2rem;
    }
  }

  .quote-block {
    &--page-content-2-quote-block {
      @media (min-width: 700px) {
        max-width: 36rem;
      }

      & > div {
        height: 100%;

        .quote-block-inner {
          height: 100%;
        }
      }
    }
  }

  .i-want-great-care-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: 1200px) {
      align-items: center;
      flex-direction: row;
    }

    .i-want-great-care-intro {
      @media (min-width: 1200px) {
        flex-shrink: 0;
      }
    }

    .arrow-link--i-want-great-care-link {
      @media (min-width: 1200px) {
        flex-grow: 0;
        margin: 0;
        transform: translateY(-0.1rem);
        width: auto;
      }
    }

    .five-star-wrapper {
      display: flex;
      flex-direction: row;

      @media (min-width: 1200px) {
        align-items: center;
        flex-shrink: 0;
      }

      .five-star-reviews {
        margin-bottom: 0;
        margin-right: 1rem;
      }

      .five-stars-svg {
        height: 1.96rem;
        width: 9rem;
        transform: translateY(-0.2rem);
      }
    }
  }
`
